export const flavorColor = "#86c232";
export const flavorColor2 = "#61892f";
export const backgroundColor = "#000";
export const darkGray = "#47484f";
export const lightGray = "#6b6e70";
export const notWhite = "#ebebeb";

//191c1f
//blue 
//green -  #17eb97 - #10a469
//notblack - #0b0c10